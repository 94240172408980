import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class Preloader {
  constructor() {}
  //   startPreloader() {
  //     let counterElement = document.querySelector(".counter");
  //     let currentValue = 0;

  //     function updateCounter() {
  //       if (currentValue === 100) {
  //         return;
  //       }

  //       currentValue += Math.floor(Math.random() * 10) + 1;

  //       if (currentValue > 100) {
  //         currentValue = 100;
  //       }

  //       counterElement.textContent = currentValue;
  //       let delay = Math.floor(Math.random() * 200) + 50;
  //       setTimeout(updateCounter, delay);
  //       console.log(currentValue);
  //     }

  //     updateCounter();
  //   }

  animation() {
    gsap.to(".preloader__left", 0.25, {
      delay: 1,
      xPercent: -100,
    });

    gsap.to(".preloader__right", 0.25, {
      delay: 1,
      xPercent: 100,
    });

    gsap.to(".preloader", 1.5, {
      delay: 0.5,
      display: "none",
    });
  }
}
